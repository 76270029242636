import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
// import Cta from '../components/sections/Cta';

import FeaturesTiles from '../components/sections/FeaturesTiles';
import HistoricChart from '../components/historicsats/HistoricChart'
import Calculator from '../components/historicsats/Calculator'

import twitter from "../assets/images/social-icons/twitter-icon.png"
import facebook from "../assets/images/social-icons/facebook-icon.png"
import telegram from "../assets/images/social-icons/telegram-icon.png"
import linkedin from "../assets/images/social-icons/linkedin-icon.png"
import instagram from "../assets/images/social-icons/instagram-icon.png"
import youtube from "../assets/images/social-icons/youtube-orig-icon.png"
import github from "../assets/images/social-icons/github-icon.png"


const Home = () => {

  return (
    <>
    {/* <div className="icon-bar-desktop .d-none .d-sm-block">
        <a class="c-link"  href="https://twitter.com/xsatoshis" target="_blank" rel = "noopener noreferrer">
            <img class="c-public-footer__social-icon" alt="Twitter icon" src={twitter} target="_blank" />
        </a>
        <a class="c-link"  href="https://t.me/xsatoshis" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="Twitter icon" src={telegram} target="_blank" />
        </a>
        <a class="c-link"  href="https://www.linkedin.com/company/xsats" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="LinkedIn icon" src={linkedin} />
        </a>
        <a class="c-link"  href="https://www.facebook.com/xsats" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="Facebook icon" src={facebook} />
        </a>
      <a class="c-link"  href="https://www.instagram.com/xsatoshis" target="_blank" rel = "noopener noreferrer">
          <img class="c-public-footer__social-icon" alt="Instagram icon" src={instagram} />
      </a>
      <a class="c-link"  href="https://www.youtube.com/channel/UC8YHMMidbNVKXeRaoEk9r6g" target="_blank" rel = "noopener noreferrer">
        <img class="c-public-footer__social-icon" alt="YouTube icon" src={youtube} />
      </a>
      <a class="c-link"  href="https://www.github.com/xsats" target="_blank" rel = "noopener noreferrer">
        <img class="c-public-footer__social-icon" alt="GitHub icon" src={github} />
        </a>
    </div> */}
      <Hero />
      {/* <FeaturesTiles /> */}
      {/* <div className="opportunityCostStatementContainer">
      <div className="opportunityContainer">
        <div className="calculator">
          <Calculator />
        </div>

        <div className="historicChart">
          <HistoricChart />
        </div>
      </div>
      </div> */}
      {/* <Chart/> */}
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;