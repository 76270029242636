import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Image from '../elements/Image';
import whitepaper from '../../assets/documents/bitcoin-whitepaper-satoshi-nakamoto.pdf';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Heating. <span className="text-color-primary">Transformed</span>.
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                <span className="text-color-light">Energy hardware and software solutions,<br /> optimised for the <strong>digital age</strong>.</span> 
              </p>
              {/* <div className="reveal-from-bottom" data-reveal-delay="600"> */}
                {/* <ButtonGroup> */}
                    {/* <a  href="https://app.xsats.com/x" target = "_blank" rel = "noopener noreferrer" className="button button-secondary button--max-size-mobile button-sm" >BUY BITCOIN</a> */}
                  {/* <a
                    href="https://apps.apple.com/gb/app/xsats/id1525551447" 
                    target = "_blank" 
                    rel = "noopener noreferrer">
                    <Image
                      className="c-public-footer__app-icon"
                      src={require('./../../assets/images/apple-app-store-badge.png')}
                      alt="Hero"
                      width={225}
                      height={100} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.xsats.xsatsag" 
                    target = "_blank" 
                    rel = "noopener noreferrer">
                    <Image
                      className="c-public-footer__app-icon"
                      src={require('./../../assets/images/google-play-badge.png')}
                      alt="Hero"
                      width={260}
                      height={100} />
                  </a> */}
                {/* </ButtonGroup> */}
              {/* </div> */}
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            {/* <a href={whitepaper} target="_blank"> */}
              <Image
                className="has-shadow"
                src={require('./../../assets/images/genesis.png')}
                alt="Hero"
                width={996}
                height={704}
                opacity="10%" />
            {/* </a> */}
            <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="300">
                <span className="text-color-light">Where it all began.</span> 
              </p>
          </div>
        </div>
      </div>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Coming soon.
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;